import { TEXT_VARIANTS } from 'constants/text';
import React from 'react';
import HomePageComponent from 'style-book/home-page/HomePageComponent';
import SeoComponent from 'style-book/seo/SeoComponent';
import TextComponent from 'style-book/text/TextComponent';

import './CookiesPolicyScreen.scss';

/* eslint-disable max-lines-per-function */
export default function CookiesPolicyScreen() {
  return (
    <HomePageComponent>
      <div className="cookies-policy-screen">
        <div className="cookies-policy-content">
          <TextComponent variant={TEXT_VARIANTS.H1}>Cookies Policy</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.H3}>Last Updated: January 14, 2022</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.H3}>Welcome to Goalskeeper.io!</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`This cookie policy explains how and why cookies and other similar technologies may be stored on and accessed from your device when you use or visit:`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.LINK} href="https://goalskeeper.io">
                https://goalskeeper.io
              </TextComponent>
            </TextComponent>
            {` Will be under responsibility and in charge of: `}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              <TextComponent variant={TEXT_VARIANTS.LINK} href="mailto:legal@goalskeeper.io">
                legal@goalskeeper.io
              </TextComponent>
            </TextComponent>
            {`(Hereinafter referred to as “Goalskeeper.Io”).`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`This cookie policy should be read together with our privacy policy and our terms and conditions.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_MEDIUM}>
            {`By using the website, you accept the use of cookies by Goalskeeper.Io, in the terms contained in this policy.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>1. WHAT ARE COOKIES?</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Cookies are small text files that are stored on your computer or mobile device when you visit a website. They allow the website to recognize your device and remember if you have been to the website before. Cookies are a very common web technology; most websites use cookies and have done so for years. Cookies are widely used to make the website work more efficiently. Cookies are used to measure which parts of the website users visit and to personalize their experience. Cookies also provide information that helps us monitor and improve the performance of the website.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            2. REFUSING OR WITHDRAWING CONSENT TO THE USE OF COOKIES
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`If you do not want cookies to be dropped on your device, you can adjust the setting of your Internet browser to reject the setting of all or some cookies and to alert you when a cookie is placed on your device. For further information about how to do so, please refer to your browser ‘help’, ‘tool’, or ‘edit’ section. Please note that if you use your browser settings to block all cookies, including strictly necessary cookies, you may not be able to access or use all or parts of the functionalities of Goalskeeper.Io.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`If you want to remove previously-stored cookies, you can manually delete the cookies at any time. However, this will not prevent Goalskeeper.Io from placing further cookies on your device unless and until you adjust your Internet browser setting as described above.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`We provide the links for the management and blocking of cookies depending on the browser you use:`}
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              {`Microsoft Edge: `}
              <TextComponent
                variant={TEXT_VARIANTS.LINK}
                href={`https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us`}>
                {`https://support.microsoft.com/en-us/office/delete-cookies-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09?ui=en-us&rs=en-us&ad=us`}
              </TextComponent>
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              {`Firefox: `}
              <TextComponent
                variant={TEXT_VARIANTS.LINK}
                href={`https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox`}>
                {`https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox`}
              </TextComponent>
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              {`Chrome: `}
              <TextComponent
                variant={TEXT_VARIANTS.LINK}
                href={`https://support.google.com/chrome/answer/95647?hl=en`}>
                {`https://support.google.com/chrome/answer/95647?hl=en`}
              </TextComponent>
            </TextComponent>
            <TextComponent variant={TEXT_VARIANTS.BULLET}>
              {`Safari: `}
              <TextComponent
                variant={TEXT_VARIANTS.LINK}
                href={`https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac`}>
                {`https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac`}
              </TextComponent>
            </TextComponent>
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            3. FIRST-PARTY COOKIES
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`We use cookies to enhance the performance of our website and personalize your online Goalskeeper.Io experience. Cookies help us to collect information on how people use our website and which pages they visit. They enable us to monitor the number of visitors and to analyze website usage patterns and trends. We collect this information anonymously, so it does not identify anyone as an individual and no personal information is stored in our cookies. We always use cookie data in a responsible way.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            4. THIRD-PARTY COOKIES
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Third-party cookies may come from partners or third-party companies that provide functional web services or tools for our website and the optimal functioning and operation of our services. We use third-party cookies responsibly and for the sole purpose of providing optimal functioning of the platform and services. You may opt-out of these cookies by following the cookie removal information contained in this document or the technical information of the browser from which you access our website and services.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>5. SESSION COOKIES</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Session cookies are used to log in to the website with your respective credentials and password. Session cookies are also used to keep users logged in. Session cookies are temporary and are deleted from the device or browser when the session is closed and the browser is closed. We use session cookies to keep the session open when using our services and to identify you on our system each time you log on to the website.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>6. SOCIAL COOKIES</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`These cookies allow you to share our website and click “Like” on social media like Twitter, Facebook, etc. They also allow you to interact with each distinct platform’s contents. The way these cookies are used and the information gathered is governed by the privacy policy of each social platform.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>7. COOKIES WE USE</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`We use the following cookies on our website:`}
          </TextComponent>
          <div className="table-container">
            <table>
              <tbody>
                <tr>
                  <th>Cookie</th>
                  <th>Domain</th>
                  <th>Type</th>
                  <th>Description</th>
                  <th>Duration</th>
                </tr>
                <tr>
                  <td>_ga_SQHHCPLBGX</td>
                  <td>.goalskeeper.io</td>
                  <td>Analytics</td>
                  <td>This cookie is installed by Google Analytics.</td>
                  <td>2 years</td>
                </tr>
                <tr>
                  <td>_ga</td>
                  <td>.goalskeeper.io</td>
                  <td>Analytics</td>
                  <td>
                    {`The _ga cookie, installed by Google Analytics, calculates visitor, session and
              campaign data and also keeps track of site usage for the site's analytics report. The
              cookie stores information anonymously and assigns a randomly generated number to
              recognize unique visitors.`}
                  </td>
                  <td>2 years</td>
                </tr>
              </tbody>
            </table>
          </div>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>
            8. PURPOSES OF OUR COOKIES
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`Our cookies are used for the following purposes:`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_STRONG}>
              Strictly Necessary:
            </TextComponent>
            {` These cookies are essential for Goalskeeper.Io to perform its basic functions.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_STRONG}>Security:</TextComponent>
            {` We use these cookies to help identify and prevent potential security risks.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_STRONG}>
              Analytics and Performance:
            </TextComponent>
            {` Performance cookies collect information on how users interact with our website, including what pages are visited most, as well as other analytical data. We use these details to improve how our website function and to understand how users interact with them.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_STRONG}>Advertising:</TextComponent>
            {` These cookies are used to display relevant advertising to visitors who use our services or visit website we host or provide, as well as to understand and report on the efficacy of ads served on our website. They track details such as the number of unique visitors, the number of times particular ads have been displayed, and the number of clicks the ads have received. They are also used to build user profiles, including showing you ads based on products you’ve viewed on our website. These are set by Goalskeeper.Io and trusted third-party networks and are generally persistent in nature.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_STRONG}>
              GOOGLE Analytics.:
            </TextComponent>
            {` We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage and purchase history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and platform and improve our service.`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH_BOLD}>9. CONTACT US</TextComponent>
          <TextComponent variant={TEXT_VARIANTS.PARAGRAPH}>
            {`If you have questions or concerns about this cookie policy and the handling and security of your data, please contact us through our contact page or via the contact information below:`}
          </TextComponent>
          <TextComponent variant={TEXT_VARIANTS.LINK} href="mailto:legal@goalskeeper.io">
            legal@goalskeeper.io
          </TextComponent>
        </div>
      </div>
    </HomePageComponent>
  );
}

export function Head() {
  return (
    <SeoComponent
      title="goalskeeper.io | cookies policy"
      description="An explanation of how we use cookies when you visit goalskeeper.io, a small business planning web app"
      canonicalUrl="goalskeeper.io/"
    />
  );
}
